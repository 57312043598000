import React, { useContext, useEffect } from 'react';
import { Grid, Box, Container, Typography, Divider, CircularProgress, Alert, useTheme } from '@mui/material';
import PricingCard from '../components/PricingCard';
import { AdminContext } from '../context/AdminContext';
import config from '../config';

const PricingPage = () => {
  const { pricing, fetchItems, loading, error } = useContext(AdminContext);
  const theme = useTheme();

  useEffect(() => {
    console.log('trying to fetch pricing from', `${config.API_BASE_URL}/api/pricing`);
    fetchItems('pricing');
  }, []);

  const handleSelect = () => {
    window.location.href = `https://discord.gg/6sSmkyFq2K`;
  };

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', py: 8, minHeight: '100vh', background: theme.palette.background.gradient }}>
      <Container>
        <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
          Pricing
        </Typography>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h6">
            Choose a package that fits your needs
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Select from our tailored coaching packages to improve your sim racing skills
          </Typography>
        </Box>
        {loading && <CircularProgress />}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <Grid container spacing={4} justifyContent="center">
            {pricing?.length > 0 ? (
              pricing.map((item, i) => (
                <Grid item key={`pricingkey-${i}`} xs={12} sm={6} md={4}>
                  <PricingCard
                    tier={item.tier}
                    price={item.price}
                    features={item.features}
                    onSelect={() => handleSelect(item.price)}
                  />
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="text.secondary">
                No pricing items available.
              </Typography>
            )}
          </Grid>
        )}
        <Divider sx={{ my: 4 }} />
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Q&A
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
            Frequently Asked Questions
          </Typography>
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Is this a subscription service?
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              No, you pay per session or package. Each package is a one-time payment.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PricingPage;
